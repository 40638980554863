import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import array from "../../Assets/Projects/arrays.jpg" ;
import twopointer from "../../Assets/Projects/twopointer.jpg" ;
import serachingsort from "../../Assets/Projects/SortingandSearching.jpg" ;
import hashing from "../../Assets/Projects/hashing.png" ;
import stackqueue from "../../Assets/Projects/stackandqueue.png" ;
import string from "../../Assets/Projects/string.jpg" ;
import numberthory from "../../Assets/Projects/numbertheory.jpg" ;
import linkedlist from "../../Assets/Projects/linkedlist.png" ;
import recursion from "../../Assets/Projects/recursion.png" ;
import tree from "../../Assets/Projects/tree.png";
import Tries from "../../Assets/Projects/tries.png" ;
import SegmentTree from "../../Assets/Projects/segmenttree.png" ;
import graph from "../../Assets/Projects/graph.png" ;
import heap from "../../Assets/Projects/heap.png" ;
import greedy from "../../Assets/Projects/greedy.png" ;
import dp from "../../Assets/Projects/dp.png" ;


function DsaNotes()
 {
  const [totalclick, settotalclick] = useState(0);

  const handleclick = () => {

    const storedTotalClick = localStorage.getItem('totalClick');

    if (storedTotalClick >= 50) {
      console.log("your clicked is over now!!!");
    }

    else {
      settotalclick(totalclick + 1);
      localStorage.setItem('totalClick', totalclick);
      console.log("clicked number: ", totalclick);
    }
  }


  // useEffect(() => {
  //   const storedTotalClick = localStorage.getItem('totalClick');

  //   if (storedTotalClick > 2) {
  //     console.log("your clicked is over now!!!");
  //   }
  //   else{
  //     localStorage.setItem('totalClick', totalclick+1);
  //   }

  // }, [totalclick]) 

  return (
    <Container fluid className="project-section">
      <Particle />

      <Container>
        <h1 className="project-heading">
        Complete DSA<strong className="purple"> Notes</strong>
        </h1>

        <p style={{ color: "white" }}>
         
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={array}
              isBlog={false}
              title="Array DataStructure"
              description=""
              demoLink="https://docs.google.com/document/d/1WQjlzZx73TiJvL-voIp6VbxmTlD9gAY-JDXuGMsxCgc/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={twopointer}
              isBlog={false}
              title="Two pointer"
              description=""
              demoLink="https://docs.google.com/document/d/1oRnmHpmHFnLHqti6wgnE7pP1GKT4fifBXEE_ZRJkkmg/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={serachingsort}
              isBlog={false}
              title="Searching and Sorting Algorithms"
              description=""
              demoLink="https://docs.google.com/document/d/1QP5W6b6SBTTWlXvpX6EnNECQzBEncUwFW3h4hf1yQ9Q/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hashing}
              isBlog={false}
              title="Hashing 
              technique"
              description=""
              demoLink="https://docs.google.com/document/d/1EAKKEboegeqlWUQWKAlZZ3QJFzqN-lH5YqzXpbFfaks/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={stackqueue}
              isBlog={false}
              title="Complete Stack and Queue"
              description=""
              demoLink="https://docs.google.com/document/d/1l_s_i2pMHPn5ZK8g86iLiCEqS-Gg_V9p6cQ2LPh-isc/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={string}
              isBlog={false}
              title="Complete String"
              description=""
              demoLink="https://docs.google.com/document/d/1P-8bhoXKosq2MDLC7H_eVrny5PvNUihkOx9oxrST6m8/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={numberthory}
              isBlog={false}
              title="Number Theory and Mathematics"
              description=""
              demoLink="https://docs.google.com/document/d/1s_52WBhEUr0iXcENTE8tXeQGfCwinsm5zQC3JJ5S49s/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>


          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={recursion}
              isBlog={false}
              title="Recursion"
              description="Article On the Mathematics Required for the coding "
              demoLink="https://docs.google.com/document/d/1I2_vi13ZaK7LW0-UHDzAFPPl2WNEhA5kYVwNoQHRB64/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>


          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={linkedlist}
              isBlog={false}
              title="Linked List"
              description="Article On the Mathematics Required for the coding "
              demoLink="https://docs.google.com/document/d/16ifvgpWzAKFQCPNUJHp14Y9fY-_vg1Qx2TWd_xlylRI/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

          {/* //tree... */}
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={tree}
              isBlog={false}
              title="Tree Data Structure"
              description=""
              demoLink="https://docs.google.com/document/d/1iMqUTNbJwN8RKtnC-qMVLPHufMqUJIjRCKoZEQdNZY8/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

          {/* //heap... */}
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={heap}
              isBlog={false}
              title="heap/priority_queue Data Structure"
              description=""
              demoLink="https://docs.google.com/document/d/1UN_R4BZvrbdHjOLURnipqksSSFYTPnGG7RKfKJgjHEg/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>


          {/* //greedy... */}
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={greedy}
              isBlog={false}
              title="important greedy algorithms."
              description=""
              demoLink="https://docs.google.com/document/d/1pSyXc6g-u5OXoBPsHYkxNIEbzz3K395nnZjqni9wJ_g/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>
           
          {/* //dyanamic programming concept... */}
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={dp}
              isBlog={false}
              title="Dyanamic Programming."
              description=""
              demoLink="https://docs.google.com/document/d/1ph4Cz6c11DtTmpaRKq7lNGGrJtD_OE5DDzN5Oeh6sz0/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>


         {/* //graph.. */}
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={graph}
              isBlog={false}
              title="graph Data Structure"
              description=""
              demoLink="https://docs.google.com/document/d/1X2cgr24vFSbrkj4sVKdTKarKy6l8_J1bkxbuPvaTBKs/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />

          </Col>


         {/* //advanced data structure..  segment tree */}
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={SegmentTree}
              isBlog={false}
              title="Segment Tree"
              description=""
              demoLink="https://docs.google.com/document/d/18VJr03sjQryD365JeCQmPWxIQrJwJTFTzy8oV4TLSlk/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />

          </Col>


         {/* //tries.. */}
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={Tries}
              isBlog={false}
              title="Tries Data Structure"
              description=""
              demoLink="https://docs.google.com/document/d/1DJKZwM4Hj5JyC_kAH-ziZQVQF4ywsqU4S7ob7QmLFkQ/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />

          </Col>


         {/* //chat project */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={"#"}
              isBlog={false}
              title="about the chat project"
              description=""
              demoLink="https://docs.google.com/document/d/1Wh92nC2o9zfwEkpu3H4KTos1SIfmKP66QiIE_uw3F5o/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

         {/* //about the study platform... */}
         <Col md={4} className="project-card">
            <ProjectCard
              imgPath={"#"}
              isBlog={false}
              title="about the study platform.."
              description=""
              demoLink="https://docs.google.com/document/d/1wMzCODZtIr6TQrV_Hv0V2x89BEOicCsH5mt8z7khQBI/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

          {/* //about the argumented reality project.. */}
          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={"#"}
              isBlog={false}
              title="about the argumented reality project."
              description=""
              demoLink="https://docs.google.com/document/d/1FgxiRyptFwo5Sgb0GSChkf2vVo8dUTNy5kfcM5yRtKk/edit?usp=sharing"
              handleclick={handleclick}
              totalclick={totalclick}
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default DsaNotes;
